

import { getHomeData, $getNoticeData } from "@/api/modules/home_api.js";
import { defineStore } from 'pinia'
import _filterTardeCover from '@/filters/filter_tarde.js'
export const useHomePinia = defineStore('useHome', {
    state() {
        return {
            news: [],
            plat: [],
            loading: false,
            platCount: {
            }, // 企业用户信息
        }
    },
    actions: {
        async initHomeData() {
            this.loading = true
            let res = await getHomeData()
            let res2 = await $getNoticeData()
            let { status, data } = res
            if (status) {
                let temp = {}
                // 合并数据
                if (res2.status == 1) {
                    temp.column_title = '平台公告'
                    temp.id = -1
                    temp.news_list = res2.data
                    temp.type = 20 // 20 代表公司公告
                }
                // 把公司公告的数据 换到上面
                this.news = _filterTardeCover([...data.news.slice(0, 3), temp])

                
                this.plat = data.plat.filter(item => {
                    item.cover = item.cover.replace(/http/g, 'https')
                    return item
                })
                this.platCount = data.platCount
                this.loading = false
            }
        }
    }
})