


import { defineStore } from 'pinia'
import { getCompanyList } from '@/api/modules/home_api.js'
import _, { flatten } from 'lodash'
export const useCompany = defineStore('company_rem', {
    state() {
        return {
            company_rem: {
                left: [], // 左边
                right: [],// 右边
            }, //列表
            req: false,
            params: {
                limit: 20,
                type: 0,
                region_id: '',
                info: ''
            },
            loading: false
        }
    },
    getters: {
        flatState() {
            return {
                companyTopTen: this.company_rem.left.flat(Infinity),
                company: this.company_rem.right.flat(Infinity)
            }
        },
    },
    actions: {
        async init() {
            if (this.req) return
            this.loading = true
            let res = await getCompanyList()
            let { status, data } = res
            if (status) {
                this.company_rem.left = data.company.slice(0, 10)
                this.company_rem.right = data.company.slice(10)
                this.loading = false
            }
        },
        async search() {
            this.loading = true
            let res = await getCompanyList(this.params)
            let { status, data } = res
            if (status) {
                /*      const result1 = handler(data.company)
                     const result2 = handler(data.companyTopTen) */
                if (this.params.type == 1) {
                    // this.company_rem.companyTopTen = _.chunk(data.companyTopTen, 2)
                    // this.company_rem.company = _.chunk(data.companyTopTen, 2)
                    this.company_rem.left = data.company.slice(0, 10)
                    this.company_rem.right = data.company.slice(10)
                } else {
                    this.company_rem.left = data.company.slice(0, 10)
                    this.company_rem.right = data.company.slice(10)
                }
                this.loading = false
            }
            this.req = true
        }
    }
})
