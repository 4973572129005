

import supply1 from '@/assets/默认图/1-1.jpg'
import supply2 from '@/assets/默认图/1-2.jpg'
import supply3 from '@/assets/默认图/1-3.jpg'


import logisticsImg1 from '@/assets/默认图/2-1.jpg'
import logisticsImg2 from '@/assets/默认图/2-2.jpg'
import logisticsImg3 from '@/assets/默认图/2-3.jpg'

import adjustmentInfoImg1 from '@/assets/默认图/3-1.jpg'
import adjustmentInfoImg2 from '@/assets/默认图/3-2.jpg'
import adjustmentInfoImg3 from '@/assets/默认图/3-3.jpg'


import announcements1 from '@/assets/默认图/4-1.jpg'
import announcements2 from '@/assets/默认图/4-2.jpg'

export default {
    // 最新供求默认图
    '供求快讯': [supply1, supply2, supply3],
    // 仓库物流默认图
    '仓储物流': [logisticsImg1, logisticsImg2, logisticsImg3],
    // 调价信息
    '调价信息': [adjustmentInfoImg1, adjustmentInfoImg2, adjustmentInfoImg3],
    //通知公獒
    '通知公告': [announcements1, announcements2]
}