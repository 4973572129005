

// 过滤默认的图片
export const filterDefault = (cover, type) => {
    let imgUrl = ''
    switch (type) {
        case 39:
            imgUrl = 'icon-yly_jiancai' //螺纹建材
            break;
        case 38:
            imgUrl = 'icon-xiancailei' // 盘螺
            break;
        case 37:
            imgUrl = 'icon-xiancai4' // 线材
            break;
        case 47:
            imgUrl = 'icon-yly_buxiugang'  // 光圆钢筋
            break
        case 58:
            imgUrl = 'icon-yly_zhongban' // 其他
            break

    }
    return imgUrl
}