
import supply1 from '@/assets/默认图/1-1.jpg'
import logisticsImg1 from '@/assets/默认图/2-1.jpg'
import adjustmentInfoImg1 from '@/assets/默认图/3-1.jpg'
import announcements1 from '@/assets/默认图/4-1.jpg'

const imgArr = [supply1, logisticsImg1, adjustmentInfoImg1, announcements1]
/* 
    过滤螺纹钢网首页贸易商机中的 图片 如果没有的话采用默认图
*/

export default function _filterTardeCover(arr = []) {
    const result = arr
    const len = result.length
    if (!len) return

    result.forEach((item, index) => {
        handlerFun(item.news_list, index)
    })

    return result
}

function handlerFun(row, i) {
    const len = row.length
    if (!len) return
    row.forEach(v => {
        if (v.cover) {
            v.img = "https://cdn.gangxinbao.cn/" + v.cover.split(',')[0]
        } else {
            v.img = imgArr[i]
        }
    })

}


