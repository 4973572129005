import { useCssVars as _useCssVars } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1881c6e9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-img" }
const _hoisted_2 = { class: "theme-default banner-warp shop_list_bg" }
const _hoisted_3 = {
  id: "slider",
  class: "nivoSlider"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "w_1200 container" }
const _hoisted_6 = { class: "show-left-warp" }
const _hoisted_7 = { class: "show-right" }
const _hoisted_8 = { class: "show-user" }
const _hoisted_9 = { class: "position_left position" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "position_right position" }
const _hoisted_12 = ["src"]

import "@/js/jquery-1.4.2.min";
import "@/js/jquery.nivo.slider.pack.js";
import {
  computed,
  isShallow,
  nextTick,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  inject
} from "vue";
import ShowUserVue from "./ShowUser.vue";
import { useImage } from "@/hooks/useImage.js";
import { useRouter } from "vue-router";
import NavTabs from "./NavTabs/NavTabs.vue";
import { useAdv } from '@/pinia/api/useAdv.js'

export default {
  setup(__props) {

_useCssVars(_ctx => ({
  "be56305c": (_unref(marginLeft))
}))

const bannerStore = useAdv()
const loginShow = inject('login_show')
// 计数
const count = ref(0);
// 当前要展示的横幅

const current = computed(() => {
  return bannerStore.bannerList[count.value];
});
const isSHowH = ref(false);



const _initSwiper = () => {
  setTimeout(() => {
    nextTick(() => {
      require("@/js/jquery-1.4.2.min.js");
      require("@/js/jquery.nivo.slider.pack.js")
      console.log("jq版本:", $().jquery)
      // 百叶窗特效
      $(document).ready(function () {
        $("#slider").nivoSlider({
          effect: "random",
          slices: 15,
          controlNav: true,//是否使用导航控制条，即显示每张图片的按钮	
          animSpeed: 500,
          pauseTime: 3000, // 多少s轮播
          startSlide: 0, //从第几张开始
          directionNav: true, // 是否显示“上一个”和“下一个”导航
          keyboardNav: true,
          pauseOnHover: true, // 悬停是否停止？
          manualAdvance: false, // 是否手动变化·
          randomStart: false, // 是否随机开始
          beforeChange: function (curIndex) {
            isSHowH.value = false;
            if (curIndex) {
              count.value = curIndex;
            } else {
              console.log(count.value);
              if (count.value >= bannerStore.bannerList.length - 1) {
                count.value = 0;
              } else {
                count.value += 1;
              }
            }
            setTimeout(() => {
              isSHowH.value = true;
            }, 400);
          },
          /* 加载完成时触发 */
          afterLoad: function () {
            isSHowH.value = true
          },

        });
      });
    });
    isSHowH.value = true
  }, 1000)
}
onMounted(() => {
  bannerStore.$getBannList(_initSwiper)
});




const curBanner = ref(1);

let timer = null;
onMounted(() => {
  timer = setInterval(() => {
    curBanner.value = curBanner.value + 1;
    if (curBanner.value > bannerStore.bannerList.length) {
      curBanner.value = 1;
    }
  }, 4000);
});

onUnmounted(() => {
  clearInterval(timer);
});

const router = useRouter();
/* const store = useClassIfy(); */
/* if (!store.classIfy.length) {
  store.initClassIfy();
}
 */

const marginLeft = useImage();

// 进入店铺
const loginStore = (url) => {
  const token = localStorage.getItem('token')
  if (current.value.auth && !token) {
    loginShow()
    return
  }
  // 有的话就跳转 没的话就不用跳转了。
  url && window.open(url, "_blank");
};

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(bannerStore).bannerList, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.id,
              class: "info_box"
            }, [
              _createElementVNode("div", null, [
                _createElementVNode("img", {
                  src: item.bannerImg
                }, null, 8, _hoisted_4)
              ])
            ]))
          }), 128))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(NavTabs, { isShow: true })
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(ShowUserVue)
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_Transition, { name: "fade" }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("img", {
              src: _unref(current)?.hengfu1,
              alt: "",
              onClick: _cache[0] || (_cache[0] = $event => (loginStore(_unref(current)?.hengfu1Link)))
            }, null, 8, _hoisted_10), [
              [_vShow, isSHowH.value]
            ])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_Transition, { name: "fade" }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("img", {
              src: _unref(current)?.hengfu2,
              alt: "",
              onClick: _cache[1] || (_cache[1] = $event => (loginStore(_unref(current)?.hengfu2Link)))
            }, null, 8, _hoisted_12), [
              [_vShow, isSHowH.value]
            ])
          ]),
          _: 1
        })
      ])
    ])
  ], 64))
}
}

}