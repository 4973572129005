import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-aa4c7dee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "left_header" }
const _hoisted_3 = { class: "right_header" }
const _hoisted_4 = { class: "show" }
const _hoisted_5 = { class: "show_data" }
const _hoisted_6 = { class: "showCompany" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "showCompany" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "showCompany" }

import echarts from "../Price_hq/echarts.vue";
import hq_header from "../common/hq_header.vue";
import { useHomePinia } from "@/pinia/api/home.js";
import { useCompany } from "@/pinia/api/company_rem.js";
import { ref } from "vue";
import { storeToRefs } from "pinia";

export default {
  setup(__props) {

const companyStore = useCompany();
companyStore.init();
const curTabs = ref(0);

const loginStore = (row) => {
  console.log(row);
  if (row.attest == 1) {
    const url = window.location.origin + `/${row.id}/company/detail`;
    window.open(url, "_blank");
  }
};

const checkout = (id) => {
  curTabs.value = id;
  companyStore.params.type = id;
  companyStore.search();
};

// 地区改变
const changeRegion = (id) => {
  companyStore.params.region_id = id;
  companyStore.search();
};

// 搜索
const searchHandler = (key) => {
  companyStore.params.info = key.value;
  companyStore.search();
};

return (_ctx, _cache) => {
  const _component_vue3_seamless_scroll = _resolveComponent("vue3-seamless-scroll")
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(hq_header, {
          title: "企业推荐",
          styleT: "linear-gradient(90deg, #CC0000, #F45959)",
          border: "1px solid #CC0000",
          placeholder: "请输入公司名称进行查询",
          onChangeQy: changeRegion,
          onRegion_change: changeRegion,
          onSearch: searchHandler
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(hq_header, {
          isShowSearch: false,
          title: "行情走势",
          styleT: "linear-gradient(90deg, #1677FF, #5CA0FF)",
          border: "1px solid #1677FF",
          showMore: false
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _withDirectives((_openBlock(), _createElementBlock("ul", _hoisted_6, [
          _createVNode(_component_vue3_seamless_scroll, {
            list: _unref(companyStore).company_rem.left,
            step: 0.4,
            hover: true
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(companyStore).company_rem.left, (item) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: item.id
                }, [
                  _createElementVNode("span", {
                    onClick: $event => (loginStore(item)),
                    class: _normalizeClass([item.attest == 1 ? 'valid_company' : '', "company supplier"])
                  }, _toDisplayString(item.name), 11, _hoisted_7)
                ]))
              }), 128))
            ]),
            _: 1
          }, 8, ["list", "step"])
        ])), [
          [_directive_loading, _unref(companyStore).loading]
        ]),
        _withDirectives((_openBlock(), _createElementBlock("ul", _hoisted_8, [
          _createVNode(_component_vue3_seamless_scroll, {
            list: _unref(companyStore).company_rem.right,
            step: 0.4,
            hover: true
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(companyStore).company_rem.right, (item) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: item.id
                }, [
                  _createElementVNode("span", {
                    onClick: $event => (loginStore(item)),
                    class: _normalizeClass([item.attest == 1 ? 'valid_company' : '', "company supplier"])
                  }, _toDisplayString(item.name), 11, _hoisted_9)
                ]))
              }), 128))
            ]),
            _: 1
          }, 8, ["list", "step"])
        ])), [
          [_directive_loading, _unref(companyStore).loading]
        ]),
        _createElementVNode("ul", _hoisted_10, [
          _createVNode(echarts)
        ])
      ])
    ])
  ]))
}
}

}