import { createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-355d2baa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "big-echarts" }

import MaskVue from "@/components/common/Masker.vue";
import MockData from "../Price_hq/data"; //mock数据
import {
  onMounted,
  ref,
  computed,
  onUnmounted,
  watchEffect,
  reactive,
  nextTick,
  watch,
  shallowRef,
} from "vue";
import axios from "@/api/axios.js";
import * as echarts from "echarts";

export default {
  props: ["keyWord"],
  setup(__props) {

const props = __props

const ecahrtTitle = shallowRef("");
const isShow = ref(false); //是否方法展示图表
// 设置图表显示的方法
const setIsShow = (flag) => {
  isShow.value = flag;
};

const proportion = ref(null); // 小的实例
const proportion2 = ref(null); // 大的实例
const data = ref([]); // 图表的数据
let myChart1 = null; // 图标实例
let myChart2 = null; // 大的实例
const titleArr11 = ref([]);
// 查看大的图标
const lookBigEcharts = () => {
  setIsShow(true);
  // 初始化 echarts
  mountBigEcharts();
  nextTick(() => {
    onKeyChange(null, myChart2);
  });
};

watch(
  () => props.keyWord,
  (val) => {
    nextTick(() => {
      onKeyChange(val, myChart1);
    });
  },
  { immediate: true }
);

// 分成五份 每一份都自己处理
const handlerData = (arr = []) => {
  console.log(arr);
  // 先获取 title 日期
  const titleArr = arr[0].trend.map((item) => {
    return item.set_time;
  });
  titleArr11.value = titleArr;
  ecahrtTitle.value = arr[0].trend[0].name;
  return arr.map((v, index) => {
    const data = v.trend.map((item) => {
      // return [item.set_time, item.price];
      // 传时间戳测试一下
      return [new Date(item.set_time).getTime(), item.price];
    });
    return {
      name: v.region_title,
      id: v.id,
      type: "line",
      data: [...data],
      symbolSize: 0,
      showSymbol: false,
      smooth: 0.2,
      itemStyle: {
        normal: {
          lineStyle: {
            width: 1.2, // 0.1的线条是非常细的了
          },
        },
      },
    };
  });
};

const onKeyChange = async (key, instance) => {
  instance.showLoading();
  // 请求数据
  let res = await axios.post("/test/trend");
  const temp = handlerData(res.data.trend);
  data.value = temp;
  instance.hideLoading();
  instance.setOption(options.value);

  // mock
  /*   setTimeout(() => {
    let res = MockData;
    const temp = handlerData(res);
    data.value = temp;
    instance.hideLoading();
    instance.setOption(options.value);
  }, 300); */
};

// 图表的配置项
const options = computed(() => {
  return {
    tooltip: {
      trigger: "axis",
      bottom: -80,
      textStyle: {
        fontSize: 12,
        fontWeight: 500,
        color: "#fff",
      },
      formatter: function (params) {
        // params数组可以了解一下，一条线和多条线都是数组
        var html = "";
        for (var i in params) {
          var param = params[i];
          // echarts日期格式化api
          var date = echarts.format.formatTime(
            "yyyy/MM/dd",
            new Date(param.value[0])
          );
          const dotHtml = params[i].marker; // 提示框示例的小圆圈,可以在这里修改
          if (i == 0) {
            html += `${date}<br />${dotHtml}${param.seriesName}：<span style="color: #FFFFFF">${param.value[1]}</span><br />`;
          } else {
            html += `${dotHtml}${param.seriesName}：<span style="color: #FFFFFF">${param.value[1]}</span><br />`;
          }
        }
        return html;
      },
      backgroundColor: "rgba(111, 111, 111,.95)",
    },
    legend: {
      bottom: 0,
      tooltip: {
        show: true,
      },
    },
    /* 控制视图的比例 */
    grid: {
      left: "4%",
      bottom: "10%",
      containLabel: true,
      show: true,
      borderColor: "#aaa",
    },
    title: {
      text: ecahrtTitle.value + "价格走势图",
      left: 50,
      top: 10,
      textStyle: {
        fontSize: 14,
      },
    },
    xAxis: {
      type: "time", // type为time时,不要传xAxis.data的值,x轴坐标的数据会根据传入的时间自动展示
      boundaryGap: false, // false横坐标两边不需要留白
      splitNumber: 4,
      min(value) {
        return value.min;
      },
      max(value) {
        return value.max;
      },
      axisLabel: {
        margin: 10,
        // 坐标轴标签样式设置
        formatter: function (value, index) {
          // 坐标轴文字展示样式处理
          const date = new Date(value);

          const texts = [
            date.getFullYear(),
            date.getMonth() + 1,
            date.getDate(),
          ];
          return isShow.value ? texts.join("/") : "";
          // echarts自带的日期格式化方法
          // return echarts.format.formatTime('yyyy-MM-dd', value)
        },
      },
      splitLine: {
        show: true,
        lineStyle: {
          type: "dotted",
        },
      },
    },
    yAxis: {
      type: "value",
      min: function (value) {
        return parseInt(value.min);
      },
      max: function (value) {
        return parseInt(value.max);
      },
    },
    series: [...data.value],
  };
});

// 挂载大的 echarts
const mountBigEcharts = (container) => {
  myChart2 = echarts.init(proportion2.value);
  myChart2.setOption(options.value);
  myChart2.showLoading();
  nextTick(() => {
    window.addEventListener("resize", echartResize);
  });
};
// 初始化时
onMounted(() => {
  myChart1 = echarts.init(proportion.value);
  myChart1.setOption(options.value);
  myChart1.showLoading();
  nextTick(() => {
    window.addEventListener("resize", echartResize);
  });
});

const echartResize = () => {
  myChart1.resize();
};

onUnmounted(() => {
  window.removeEventListener("resize", echartResize);
});

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      onClick: lookBigEcharts,
      class: "proportion",
      ref_key: "proportion",
      ref: proportion
    }, null, 512),
    _createVNode(MaskVue, {
      title: _unref(ecahrtTitle),
      isShow: isShow.value,
      setShow: setIsShow
    }, {
      echarts: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", {
            class: "proportion2",
            ref_key: "proportion2",
            ref: proportion2
          }, null, 512)
        ])
      ]),
      _: 1
    }, 8, ["title", "isShow"])
  ], 64))
}
}

}