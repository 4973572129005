
import {
    ref,
    onMounted,
    onBeforeUnmount
} from 'vue'
import _ from 'lodash'
export const useScroll = (scrollContainer, slice, cb) => {
    let time = ref(null)
    let i = 1
    let len = scrollContainer.value?.children.length
    function changePosition() {
        if (i >= slice && i < len) {
            cb('钢信宝动态')
        } else {
            if (i >= len) {
                i = 1
                changePosition()
                return
            }
            cb('平台公告')
        }
        if (scrollContainer.value) {
            const firstCildrenNode = scrollContainer.value?.children[0];
            scrollContainer.value.appendChild(firstCildrenNode)
        }
    }
    time.value = setInterval(() => {
        changePosition()
        i++
    }, 2000)
    return time
}