

import { $getAdvData } from '@/api/modules/home_api.js'
import { defineStore } from 'pinia'
import _, { chunk, get } from 'lodash'
export const useAdv = defineStore('adv', {
    state() {
        return {
            tenImg: [], // 10条10条的广告
            threeImg: [],// 三条三条一体的广告位
            imgTo7: [], // 7个一体的
            imgTo5: [], // 5个一体的
            bottomImg: [],
            loading: false,
            bannerList: [],//banner list 
        }
    },
    actions: {
        async getAdvData() {
            this.loading = true
            let res = await $getAdvData()
            let { status, data } = res
            if (status) {
                // this.advList = data.advs
                this.tenImg = _.chunk(data.advs.top_advs, 10)
                this.threeImg = _.chunk(data.advs.center_top_advs, 3)
                this.imgTo7 = _.chunk(data.advs.center_one_advs, 4)
                this.imgTo5 = data.advs.center_two_advs
                this.bottomImg = _.chunk(data.advs.bottom_advs, 1)
                this.loading = false
            }
        },
        async $getBannList(fn) {
            let res = await $getAdvData({ sign: 'banner' })
            let id = 1
            if (res.status == 1) {
                const data = res.data.advs.top_advs
                let len = data.length
                const result = []
                for (let index = 0; index < len; index = index + 3) {
                    const item = data[index] // 横幅
                    const item1 = data[index + 1] // banner 
                    const item2 = data[index + 2]// 横幅 
                    const urlArr = item1.img.split('&')
                    console.log(item1.img,urlArr)

                    result.push({
                        id: Math.random() * Date.now(),
                        bannerImg: urlArr[0],
                        hengfu1: item.img,
                        hengfu1Link: item.link,
                        hengfu2: item2.img,
                        hengfu2Link: item2.link,
                        bannerLink: item1.link,
                        auth: urlArr[1]
                    })
                }
                this.bannerList = result
                fn()
            }
        }
    }
})