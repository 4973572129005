import {
    defineStore
} from 'pinia'
import {
    getQuoteData,
    getMoreCompanyOffer,
    $searchQuoteForInfo /* 搜索接口 */
} from '@/api/modules/quote_api.js'
import { $searchToHotWords } from "@/api/modules/home_api";

import moreHandler, { handerDataToFormat } from '../utils/moreHandler'
import {
    message
} from 'element-plus'
export const usequote = defineStore('quoteList', {
    state: () => {
        return {
            quoteList: [], //最新报价模块显示的数据
            total: 0, // 总数
            loading: false, // loading
            homeQuoteList: [],
            info: '', // 三个输入框的查询功能
            type: 0, // 分类id
            // 搜索时用到的参数
            params: {
                limit: '20', // 单页条数
                type: '0', // 类型为0 查所有
                wide: '', //最大价格 	
                tiny: '', //最小价格
                name: '', // 品名
                spec: '', // 规格
                texture: '', //材质
                regionId: '', // 地区id
                user_id: '', // 发布人id

            },
            homeParams: {
                limit: 8, // 单页条数
                type: '0',
                regionId: ''
            }, //主页的搜索
            flag: false, //最新报价点击更多时节流
            hotwords: ''// 热门搜索词
        }
    },
    getters: {},
    actions: {
        //search Home 
        async searchHome(params) {
            params = params || {}
            this.loading = true
            params = {
                ...this.homeParams,
                ...params
            }
            let res = await getQuoteData(params)
            let {
                status,
                data
            } = res
            if (status == 1) {
                this.loading = false
                this.homeQuoteList = handerDataToFormat(data.list)
            }

        },
        // 清空params的查询参数
        clearParams() {
            this.params.name = ''
            this.params.spec = ''
            this.params.texture = ''
            this.params.regionId = ''
            this.params.user_id = ''
            this.params.type = 0
        },

        //初始化 主页的8条数据
        async initHomeQuoteList(params) {
            this.loading = true
            let res = await getQuoteData({
                limit: 8,
                type: 0,
                ...params
            })
            if (res.status === 1) {
                const list = res.data.list.map(item => {
                    item.action = 'more'
                    return item
                })
                this.total = res.data.total
                this.homeQuoteList = handerDataToFormat(res.data.list) //tzc
                this.loading = false
            }
        },

        // 点击更多报价
        async clickMore(params) {
            this.loading = true
            params.user_id = this.params.user_id ? this.params.user_id : ''
            params.type_id = this.params.type ? this.params.type : 0
            let {
                target
            } = params
            if (this.flag) {
                return
            }
            this.flag = true
            /*  params = {
                 company_id: 219,
                 type_id: 14,
                 limit: 3
             } */
            let res = await getMoreCompanyOffer(params)
            let {
                status,
                data
            } = res
            if (status === 1) {
                if (target === 'offer') {
                    this.quoteList = moreHandler(this.quoteList, data, params.company_id)
                } else {
                    this.homeQuoteList = moreHandler(this.homeQuoteList, data, params.company_id)
                }
                this.flag = false
                this.loading = false
            }
        },
        // 点击收起时触发
        clickPuck(row, target) {
            if (target == 'offer') {
                this.quoteList = this.quoteList.filter(item => {
                    if (item.id == row.id && item.isChildren) {
                        return false
                    } else {
                        if (item.id == row.id) {
                            item.action = 'more'
                        }
                        return true
                    }
                })
            } else {
                this.homeQuoteList = this.homeQuoteList.filter(item => {
                    if (item.id == row.id && item.isChildren) {
                        return false
                    } else {
                        if (item.id == row.id) {
                            item.action = 'more'
                        }
                        return true
                    }
                })
            }
        },
        // 初始化数据
        async initQuoteList(params, flag) {
            params.limit = 20 //一页展示20条数据
            params.type = '0'
            // 如果已经发送过了 就不发送请求了
            if (this.quoteList.length == 0 || params.page == 1) {
                try {
                    this.loading = true
                    let res = await getQuoteData(params)
                    let {
                        status,
                        data
                    } = res
                    if (status === 1) {
                        const list = data.list.map(item => {
                            item.action = 'more'
                            return item
                        })
                        this.quoteList = handerDataToFormat(list) // 最新报价模块中的数据
                        this.total = data.total
                        this.loading = false
                    }
                } catch (error) {
                    console.log(error);
                }
            }
            // 如果传了一个flag 强制发送请求 那么就再次发送请求
            if (!flag) return
            try {
                this.loading = true
                let res = await getQuoteData(params)
                let {
                    status,
                    data
                } = res
                if (status === 1) {
                    const list = data.list.map(item => {
                        item.action = 'more'
                        return item
                    })
                    this.quoteList = handerDataToFormat(list) // 最新报价模块中的数据
                    this.total = data.total
                    this.loading = false
                }
            } catch (error) {
                console.log(error);
            }
        },

        // 通过 品名 规则 材质 进行 联合搜索
        async searchQuoteList(params) {
            params = {
                ...this.params,
                ...params,
                type: this.type
            }
            try {
                this.loading = true
                let res = await getQuoteData(params)
                let {
                    status,
                    data
                } = res
                if (status === 1) {
                    const list = data.list.map(item => {
                        item.action = 'more'
                        return item
                    })
                    this.quoteList = handerDataToFormat(list) // 最新报价模块中的数据
                    this.total = data.total // 该关键词的总条数
                    this.loading = false
                }
            } catch (error) {
                console.log(error);
            }
        },

        // 通过info进行搜索
        async searchInfo(page) {
            this.loading = true
            let res = await $searchQuoteForInfo({ info: this.info, limit: 20, page })
            let {
                status,
                data
            } = res
            if (status === 1) {
                const list = data.offers.map(item => {
                    return {
                        action: 'more',
                        offers: item,
                        regions: item.company?.regions,
                        name: item.company?.name
                    }
                })
                this.quoteList = list// 最新报价模块中的数据
                this.total = data.total // 该关键词的总条数
                this.loading = false
                //   this.info = '' // 清空搜索关键词
            }
        },

        // 通过 头部的搜索框以及热门搜索词进行搜索
        async searchToHot(params) {
            // 分页的时候可以不传 hotwords 
            if (this.hotwords) {
                params.name = this.hotwords
                params.limit = 20
            }
            this.loading = true
            let res = await $searchToHotWords(params)
            console.log(res);
            if (res.status == 1) {
                this.total = res.data.total
                this.quoteList = res.data.offers.map(item => {
                    return {
                        action: 'more',
                        name: item.company.name,
                        offers: {
                            action: 'more',
                            user: item.user,
                            ...item
                        },
                        regions: item.company.regions
                    }
                })
                console.log(this.quoteList);
                this.loading = false
            }
        },
        clearHotwords() {
            this.hotwords = ''
        }
    }
})