import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1dd2c76a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w_1200" }
const _hoisted_2 = { class: "show-counsel-img w_1200" }
const _hoisted_3 = { class: "counsel-list" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "price_marktet_warp clearfix" }
const _hoisted_7 = { class: "advss_left" }
const _hoisted_8 = { class: "adv_list" }
const _hoisted_9 = ["src", "onClick"]
const _hoisted_10 = { class: "price_marktet_box" }
const _hoisted_11 = { class: "adv2" }
const _hoisted_12 = { class: "counsel-list" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "recommend" }
const _hoisted_16 = { class: "recommend-warp" }
const _hoisted_17 = { class: "adv_list" }
const _hoisted_18 = ["onClick", "src"]
const _hoisted_19 = { class: "adv2" }
const _hoisted_20 = { class: "top-img" }
const _hoisted_21 = ["onClick", "src"]
const _hoisted_22 = { class: "top-img" }
const _hoisted_23 = ["onClick", "src"]
const _hoisted_24 = { class: "footer" }
const _hoisted_25 = ["onClick", "src"]

import PriceVue from "./components/Price_hq/index.vue"; // 价格行情
import company_recommend from "./components/company_recommend/index.vue"; // 公司推荐
import { useAdv } from "@/pinia/api/useAdv.js";


export default {
  setup(__props) {

const advStore = useAdv();
advStore.getAdvData();

const loginStore = (row) => {
  if (!row.link) return;
  const a = document.createElement("a");
  if (row.link.includes("http")) {
    a.href = row.link;
  } else {
    a.href = "http://" + row.link;
  }
  a.target = "_blank";
  a.click();
};

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(advStore).tenImg[0], (item) => {
          return (_openBlock(), _createElementBlock("li", {
            class: _normalizeClass(["counsel-item", { cp: item.link }]),
            key: item.img,
            onClick: $event => (loginStore(item))
          }, [
            _createElementVNode("img", {
              src: item.img,
              alt: ""
            }, null, 8, _hoisted_5)
          ], 10, _hoisted_4))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(advStore).threeImg[0], (item) => {
            return (_openBlock(), _createElementBlock("img", {
              class: _normalizeClass(["adv_item", { cp: item.link }]),
              key: item.id,
              src: item.img,
              onClick: $event => (loginStore(item)),
              alt: ""
            }, null, 10, _hoisted_9))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(PriceVue)
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("ul", _hoisted_12, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(advStore).tenImg[1], (item) => {
          return (_openBlock(), _createElementBlock("li", {
            class: _normalizeClass(["counsel-item", { cp: item.link }]),
            key: item.img,
            onClick: $event => (loginStore(item))
          }, [
            _createElementVNode("img", {
              src: item.img,
              alt: ""
            }, null, 8, _hoisted_14)
          ], 10, _hoisted_13))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_15, [
      _createElementVNode("div", _hoisted_16, [
        _createVNode(company_recommend)
      ]),
      _createElementVNode("div", _hoisted_17, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(advStore).threeImg[1], (item) => {
          return (_openBlock(), _createElementBlock("img", {
            class: _normalizeClass(["adv_item", { cp: item.link }]),
            onClick: $event => (loginStore(item)),
            key: item.id,
            src: item.img,
            alt: ""
          }, null, 10, _hoisted_18))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_19, [
      _createElementVNode("div", _hoisted_20, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(advStore).imgTo7[0], (item) => {
          return (_openBlock(), _createElementBlock("img", {
            onClick: $event => (loginStore(item)),
            src: item.img,
            alt: "",
            key: item.img,
            class: _normalizeClass({ cp: item.link })
          }, null, 10, _hoisted_21))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_22, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(advStore).imgTo7[1], (item) => {
          return (_openBlock(), _createElementBlock("img", {
            onClick: $event => (loginStore(item)),
            src: item.img,
            alt: "",
            key: item.img,
            class: _normalizeClass({ cp: item.link })
          }, null, 10, _hoisted_23))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_24, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(advStore).bottomImg[0], (item) => {
        return (_openBlock(), _createElementBlock("img", {
          onClick: $event => (loginStore(item)),
          key: item.img,
          src: item.img,
          alt: "",
          class: _normalizeClass({ cp: item.link })
        }, null, 10, _hoisted_25))
      }), 128))
    ])
  ]))
}
}

}